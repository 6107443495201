<template>
  <div>{{ message }}</div>
</template>

<script>

export default {
  props: {
    token: [String],
  },
  data() {
    return {
      message: this.$t('heading.ssoLoginPage.title'),
      appUrl: "",
    };
  },
  created() {
    let query = {};
    if (this.$route.query.branding) {
      query.branding = "true";
    }
    this.$router.push({ path: "/instances", query: query });
  },
};
</script>
